import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Admin from '../../assets/icons/Admin';
import Dashboard from '../../assets/icons/Dashboard';
import List from '../../assets/icons/List';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { ROLES } from '../../pages/AdminPanel/utils/formatForTables';
import ROUTES from '../../routes/route';

export const LeftSideMenu = ({ selected }) => {
  const user = useSelector((state) => state.loginReducer.user);
  const [dashboardColor, setDashboardColor] = useState('#FAFAFA');
  const [listColor, setListColor] = useState('#FAFAFA');
  const [adminColor, setAdminColor] = useState('#FAFAFA');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onTitlePress = (key) => {
    navigate(key);
  };

  // If the user is a store manager, don't render any menu items
  if (user?.roles[0] === ROLES.STORE_MANAGER) {
    return null;
  }

  return (
    <div className='leftMenuWrapper'>
      <div className='containerMenu'>
        <div className='wrapperMenu'>
          <div className='iconContainer'>
            <Tooltip title={t('dashboard')} placement='right'>
              <button
                className='buttonWithPadding'
                onClick={() => onTitlePress(ROUTES.DASHBOARD.HOME)}
                onMouseEnter={() => setDashboardColor('rgba(218, 253, 0, 1)')}
                onMouseLeave={() => setDashboardColor('#FAFAFA')}
              >
                <Dashboard
                  selected={selected === 'HOME'}
                  color={dashboardColor}
                />
              </button>
            </Tooltip>
            {/* Reporting List for Admin, Mall Manager, Mall Owner */}
            {(user?.roles[0] === ROLES.ADMIN ||
              user?.roles[0] === ROLES.MALL_MANAGER ||
              user?.roles[0] === ROLES.MALL_OWNER) && (
              <Tooltip title={t('reporting_List')} placement='right'>
                <button
                  className='buttonWithPadding'
                  onClick={() => onTitlePress(ROUTES.REPORTING_LIST)}
                  onMouseEnter={() => setListColor('rgba(218, 253, 0, 1)')}
                  onMouseLeave={() => setListColor('#FAFAFA')}
                >
                  <List selected={selected === 'LIST'} color={listColor} />
                </button>
              </Tooltip>
            )}
            {/* Admin Panel for Admin */}
            {user?.roles[0] === ROLES.ADMIN && (
              <Tooltip title={t('admin_Panel')} placement='right'>
                <button
                  className='buttonWithPadding'
                  onClick={() => onTitlePress(ROUTES.ADMIN.MALLS.LIST)}
                  onMouseEnter={() => setAdminColor('rgba(218, 253, 0, 1)')}
                  onMouseLeave={() => setAdminColor('#FAFAFA')}
                >
                  <Admin selected={selected === 'ADMIN'} color={adminColor} />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};