import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes as AppRoutes, Route, Navigate } from 'react-router';
import { admin, auth, dashboard, reportingList, reportIncome} from './route';
import { ROLES } from '../pages/AdminPanel/utils/formatForTables';
import { getUserInfo } from '../services/Saga/loginSaga/actions';

export const Routes = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loginReducer.isLoading);
  const user = useSelector((state) => state.loginReducer.user);
  const ADMIN = user?.roles?.length && user?.roles[0] === ROLES.ADMIN;
  const STORE_MANAGER =
    user?.roles?.length && user?.roles[0] === ROLES.STORE_MANAGER;
  const MALL_MANAGER =
    user?.roles?.length && user?.roles[0] === ROLES.MALL_MANAGER;
  const MALL_OWNER = user?.roles?.length && user?.roles[0] === ROLES.MALL_OWNER;

  let routes = [...auth]; // no auth default case

  if (ADMIN) {
    routes = [...dashboard, ...admin, ...reportingList, ...reportIncome];
  }
  if (STORE_MANAGER) {
    routes = [...reportIncome];
  }
  if (MALL_MANAGER || MALL_OWNER) {
    routes = [...dashboard, ...reportingList, ...reportIncome];
  }

  React.useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  if (isLoading || isLoading === undefined) return <></>;

  return (
    <AppRoutes>
      {routes.map(({ path, Component }) => (
        <Route key={path} path={path} element={<Component />} />
      ))}
      <Route path='*' element={<Navigate to={routes[0].path} replace />} />
    </AppRoutes>
  );
};
