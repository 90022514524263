import React from 'react';
import './styles.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const MallComponent = ({ selectedMall, setSelectedMall, malls }) => {
  const currentIndex = malls.findIndex(mall => mall.id === selectedMall?.id);

  const handlePrevious = () => {
    const newIndex = (currentIndex - 1 + malls.length) % malls.length;
    setSelectedMall(malls[newIndex]);
  };

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % malls.length;
    setSelectedMall(malls[newIndex]);
  };

  return (
    <div className="mall-component">
      <button className="mall-component__arrow" onClick={handlePrevious}>
        <LeftOutlined />
      </button>
      <div className="mall-component__selected">
        <span>{selectedMall?.name || 'Select Mall'}</span>
      </div>
      <button className="mall-component__arrow" onClick={handleNext}>
        <RightOutlined />
      </button>
    </div>
  );
};

export default MallComponent;